/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';

import LayoutMain from 'layouts/layout-main';
import Hero from 'components/pages/about-us/hero';
// import Features from 'components/pages/about-us/features';
import Deliberate from 'components/pages/about-us/deliberate';
// import TeamMemberHighlights from 'components/pages/about-us/team-member-highlights';
import TeamMembersGallery from 'components/pages/about-us/team-members-gallery';
import Investors from 'components/pages/about-us/investors';
import GetQuote from 'components/shared/get-quote';

const AboutUs = ({ data: { wpPage: data }, pageContext, location }) => {
  // const teamMemberHighlightsItems = data.acf.teamMemberHighlightsItems;
  const { teamMembersGalleryItems } = data.acf;
  return (
    <LayoutMain
      seo={{ ...data.seo, date: pageContext.date }}
      withBanner={pageContext.showBanner}
      pageContext={pageContext}
      location={location}
    >
      <Hero
        title={data.acf.heroTitle}
        subtitle={data.acf.heroSubtitle}
        images={data.acf.heroImages}
      />
      {/* <Features items={data.acf.featuresItems} /> */}
      <Deliberate
        title={data.acf.deliberateTitle}
        description={data.acf.deliberateDescription}
        buttonText={data.acf.deliberateButtonText}
        buttonUrl={data.acf.deliberateButtonLink.url}
      />
      {/* {teamMemberHighlightsItems && <TeamMemberHighlights items={teamMemberHighlightsItems} /> } */}
      {teamMembersGalleryItems && <TeamMembersGallery items={teamMembersGalleryItems} />}
      <Investors title={data.acf.investorsTitle} items={data.acf.investorsItems} />
      <GetQuote />
    </LayoutMain>
  );
};

export default AboutUs;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      acf {
        heroTitle
        heroSubtitle
        heroImages {
          altText
          title
          localFile {
            childImageSharp {
              gatsbyImageData(width: 580, breakpoints: [290, 580, 870, 1160])
            }
          }
        }
        featuresItems {
          title
          description
          iconName
        }
        deliberateTitle
        deliberateDescription
        deliberateButtonText
        deliberateButtonLink {
          url
        }
        teamMemberHighlightsItems {
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 240)
              }
            }
          }
          name
          position
          linkedin
          description
          quote
          quoteEmoji
        }
        teamMembersGalleryItems {
          photo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 280, height: 280, breakpoints: [140, 280, 420, 560])
              }
            }
          }
          name
          position
          linkedin
        }
        investorsTitle
        investorsItems {
          name
          url
        }
      }
      ...wpPageSeo
    }
  }
`;
